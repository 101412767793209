import React, { useCallback, useEffect, useState } from "react";
import PaymentSwitchSDK from '@inspira-npm/pg-switch-js';

var CryptoJS = require("crypto-js");
// const PGSwitchSDK = window['pg-switch-js'];
let fields = [
  {
    label: 'Flow Type',
    name: 'flow_type',
    type: 'select',
    default_value: 'pay_with_card',
    options: [
      {
        label: 'Pay with card',
        value: 'pay_with_card'
      },
      {
        label: 'Pay with VCC',
        value: 'pay_with_vcc'
      }
    ]
  },
  {
    label: 'Country Code',
    name: 'country_code',
    type: 'text',
    default_value: 'EU',
  },
  {
    label: 'Currency',
    name: 'currency',
    type: 'text',
    default_value: 'EUR',
  },
  {
    label: 'Amount',
    name: 'amount',
    type: 'text',
    default_value: '100',
  },
  {
    label: 'Subscription',
    name: 'subscription',
    type: 'text',
    default_value: false,
  },
  {
    label: 'IP',
    name: 'ip',
    type: 'text',
    default_value: '127.0.0.1',
  },
  {
    label: 'Order ID',
    name: 'order_id',
    type: 'text',
    default_value: 'inp_0dfas1',
  },
  {
    label: 'Amount to Capture',
    name: 'amount_to_capture',
    type: 'text',
    default_value: '100',
  },
  {
    label: 'Description',
    name: 'description',
    type: 'text',
    default_value: 'Description',
  },
  {
    label: 'authentication_type',
    name: 'authentication_type',
    type: 'text',
    default_value: 'authentication_type',
  },
  {
    label: 'Primary Residence',
    name: 'primary_residence',
    type: 'text',
    default_value: 'Test'
  },
  {
    label: 'Return URL',
    name: 'return_url',
    type: 'url',
    default_value: 'https://www.eminencitech.com'
  }
];
let billing = [
  {
    label: 'Line 1',
    name: 'line1',
    type: 'text',
    default_value: 'D-101',
  },
  {
    label: 'Line 2',
    name: 'line2',
    type: 'text',
    default_value: 'Agashi Road',
  },
  {
    label: 'Line 3',
    name: 'line3',
    type: 'text',
    default_value: 'Virar(west)',
  },
  {
    label: 'City',
    name: 'city',
    type: 'text',
    default_value: 'Mumbai',
  },
  {
    label: 'State',
    name: 'state',
    type: 'text',
    default_value: 'AK',
  },
  {
    label: 'ZIP Code',
    name: 'zip',
    type: 'text',
    default_value: '10001',
  },
  {
    label: 'Country',
    name: 'country',
    type: 'text',
    default_value: 'US',
  },
];
const customer = [
  {
    label: 'First Name',
    name: 'first_name',
    type: 'text',
    default_value: 'Brijesh',
  },
  {
    label: 'Last Name',
    name: 'last_name',
    type: 'text',
    default_value: 'Khatri',
  },
  {
    label: 'Email',
    name: 'email',
    type: 'text',
    default_value: 'brijesh@codestack.ae',
  },
  {
    label: 'Phone',
    name: 'phone',
    type: 'text',
    default_value: '+918989898989',
  },
];

const env = {
  uat: {
    publicKey: 'pk_test_09bf655ff5b950ea4fd95328302bc896e83d7a0725a2adfffbf7e046f62bbd38',
    url: 'https://uatapi-pg.inspiratest.com/api',
  },
  dev: {
    publicKey: 'pk_test_c910521072b6f7486931101bb7cec7fa6e9dab474f84fa1810c6d2d0b2789632',
    url: 'https://dev-routen.codestack.ae/api',
  },
  prod: {
    publicKey: 'pk_live_118af5fd0d6adba1150508c8ea2564fad3881beb83dc33a73d59f2c8f49e6caa',
    // publicKey: 'pk_test_ab4ae2a2560662a4eed135e81461035be5a1c0d96190650a35c21e4ba48c3488',
    url: 'https://inspirapay.com/api',
  },
  local: {
    publicKey: 'pk_test_ebcca4d68cc4607caa8a9a35e134f9c2ed7ee85e528db2526792339e1be75673',
    url: 'http://localhost:3000'
  }
}
const environment = 'uat'
const publicKey = env[environment].publicKey;
const url = env[environment].url
const pgSwitchSDK = new PaymentSwitchSDK(
  url,
  publicKey,
  'API_SECRET_1',
);

function DefaultPage() {

  const [cards, setCards] = useState([]);
  const [paymentId, setPaymentId] = useState('');
  const [customerData, setCustomerData] = useState({
    first_name: "Brijesh",
    last_name: "Khatri",
    email: "brijesh@codestack.ae",
    phone: "8107095482",
    phone_code: '+91'
  });
  const [fieldData, setFieldData] = useState({
    country_code: 'US',
    currency: 'USD',
    amount: 1,
    subscription: false,
    ip: '127.0.0.1',
    order_id: 'test_121',
    amount_to_capture: 1,
    description: 'Description',
    authentication_type: 'card',
    primary_residence: 'India',
    return_url: 'http://localhost:5000',
  });
  const [billData, setBillData] = useState({
    line1: 'D-101',
    line2: 'Agashi Road',
    line3: 'Virar(west)',
    city: 'Mumbai',
    state: 'Maharashtra',
    zip: '401303',
    country: 'IN',
  });
  const setCustomerDetail = (key, value) => {
    setCustomerData({
      ...customerData,
      [key]: value
    })
  }
  const setFieldDetail = (key, value) => {
    setFieldData({
      ...fieldData,
      [key]: value
    })
  }
  const setBillingDetail = (key, value) => {
    setBillData({
      ...billData,
      [key]: value
    })
  }

  useEffect(() => {
    (async () => {
      let customer = await setCustomer();
      console.log('customer', customer);
      await fetchCards();
    })();
  }, [])
  const setLoading = (isLoading, id) => {
    if (isLoading) {
      // Disable the button and show a spinner
      document
        .querySelector('#' + id + '-spinner')
        .classList.remove('hidden');
      document
        .querySelector('#' + id + '-button-text')
        .classList.add('hidden');
    } else {
      document.querySelector('#' + id + '-spinner').classList.add('hidden');
      document
        .querySelector('#' + id + '-button-text')
        .classList.remove('hidden');
    }
  }

  const setCustomer = useCallback(async () => {
    await pgSwitchSDK.createCustomer({
      first_name: customerData.first_name,
      last_name: customerData.last_name,
      email: customerData.email,
      phone: customerData.phone,
      account_id: customerData.account_id,
      address: {
        country_code: fieldData.country_code,
      },
    });
  }, [customerData, fieldData]);

  const fetchCards = async () => {
    await setCustomer();
    // $('#cardList').empty();
    console.log("Customer details", pgSwitchSDK.customer);
    const cards = await pgSwitchSDK.cards({ limit: 100 });
    if (cards && cards.length > 0) {
      setCards(cards);
    } else {
      setCards([]);
    }
    // for (let i = 0; i < cards.length; i++) {
    // const card = cards[i];
    // $('#cardList').append(`
    //   <div className="credit-card ${card.brand.toLowerCase()} selectable" >
    //     <span className="delete-card" onclick="deleteCard(event,'${card.id
    //   }')">X</span>
    //     <div onclick="charge('${card.id}','${card.token}', '${card.bin}')"> 
    //       <div className="credit-card-last4 pb-5">
    //         ${card.last4}
    //       </div>
    //       <div className="credit-card-expiry">
    //         ${card.exp_month}/${card.exp_year}
    //       </div>
    //     </div>
    //   </div>
    //   `);setCustomer
    // }
  };

  const saveCard = async (e) => {
    let result = await pgSwitchSDK.handleSaveCard(e);
    console.log(result);
  }

  const passCreditCard = useCallback(async (e) => {
    let pId = paymentId;
    let paymentElement = document.getElementById('credit-card-element');
    let buttonElement = document.getElementById('credit-card-submit');
    if (!paymentId) {
      const checksum = generateChecksum(
        `@${fieldData.currency}|${fieldData.country_code}|${parseFloat(fieldData.amount)}|${publicKey}@`,
      );
      let result = await pgSwitchSDK.init(paymentElement, buttonElement, {
        checksum,
        email: customerData.email,
        country_code: fieldData.country_code,
        return_url: fieldData.return_url,
        amount_to_capture: parseFloat(fieldData.amount_to_capture),
        description: fieldData.description,
        authentication_type: fieldData.authentication_type,
        primary_residence: fieldData.primary_residence,
        ip: fieldData.ip,
        currency: fieldData.currency,
        amount: parseFloat(fieldData.amount),
        flow_type: fieldData.flow_type,
        // subscription: false,
        metadata: {
          "org_name": "codestack_ae",
          "time": "20:20",
        },
        billing: {
          address: {
            line1: billData.line1,
            line2: billData.line2,
            line3: billData.line3,
            city: billData.city,
            state: billData.state,
            zip: billData.zip,
            country: billData.country,
            first_name: customerData.first_name,
            last_name: customerData.last_name,
          },
        },
      });
      setPaymentId(result['payment_id']);
      pId = result['payment_id']
    }
    let result1 = await pgSwitchSDK.handleCCSubmit(pId, { test: "testing" });
    console.log(result1);
    if (result1.status) {
      paymentElement.innerHtml = 'Success';
    }
  }, [paymentId]);

  const initSaveCard = useCallback(async () => {
    await setCustomer();
    setLoading(true, 'save-card');
    // document.getElementById('save-card-element').innerHTML = '';
    try {
      // make sure this id is configured correctly

      let paymentElement = document.getElementById('save-card-element');
      let buttonElement = document.querySelector('.save-card-submit');
      await pgSwitchSDK.initSaveCard(paymentElement, buttonElement, {
        amount_to_capture: parseFloat(fieldData.amount_to_capture),
        description: fieldData.description,
        authentication_type: fieldData.authentication_type,
        primary_residence: fieldData.primary_residence,
        ip: fieldData.ip,
        currency: fieldData.currency,
        amount: parseFloat(fieldData.amount),
        email: customerData.email,
        country_code: fieldData.country_code,
        billing: {
          address: {
            // line1: billData.line1,
            // line2: billData.line2,
            // line3: billData.line3,
            // city: billData.city,
            // state: billData.state,
            // zip: billData.zip,
            country: billData.country,
            // first_name: customerData.first_name,
            // last_name: customerData.last_name,
          },
        },
        // metadata:{
        //   language_code:'es-ES',
        // }
      });
      if (buttonElement) {
        buttonElement.removeEventListener('click', saveCard, false);
        buttonElement.addEventListener('click', saveCard, false);
      }
    } catch (e) {
      console.log('Error during initialization: ', e);
    }
    setLoading(false, 'save-card');
  }, [customerData, fieldData, billData])

  const initPayment = useCallback(async () => {
    await setCustomer();
    setLoading(true, 'payment');
    // document.getElementById("payment-element").innerHTML = '';
    // initializePgSwitch();
    const response = await initializePgSwitch();
    setLoading(false, 'payment');
  }, [customerData, fieldData, billData]);

  const confirmPayment = useCallback(async () => {
    setLoading(true, 'payment');
    const response = await pgSwitchSDK.capturePayment({
      payment_id: paymentId,
    });
    setLoading(false, 'payment');
  }, [customerData, fieldData, billData, paymentId]);

  const generateChecksum = (data) => {
    const hash = CryptoJS.SHA256(data);
    return hash.toString(CryptoJS.enc.Hex);
  }

  const charge = async (cardId, token, bin) => {
    await setCustomer();
    // setLoading(true, 'save-card');
    try {
      const checksum = generateChecksum(
        `@${fieldData.order_id}|${fieldData.currency}|${fieldData.country_code}|${parseFloat(fieldData.amount)}|${publicKey}@`,
      );

      // make sure this id is configured correctly
      let result = await pgSwitchSDK.charge({
        checksum,
        card_id: cardId,
        bin: bin,
        email: customerData.email,
        amount_to_capture: parseFloat(fieldData.amount_to_capture),
        description: fieldData.description,
        authentication_type: fieldData.authentication_type,
        primary_residence: fieldData.primary_residence,
        ip: fieldData.ip,
        currency: fieldData.currency,
        order_id: fieldData.order_id,
        amount: parseFloat(fieldData.amount),
        subscription: fieldData.subscription,
        country_code: fieldData.country_code,
        flow_type: fieldData.flow_type,
        billing: {
          address: {
            line1: billData.line1,
            line2: billData.line2,
            line3: billData.line3,
            city: billData.city,
            state: billData.state,
            zip: billData.zip,
            country: fieldData.country,
            first_name: customerData.first_name,
            last_name: customerData.last_name,
          },
        },
      });
      console.log("Charge Result:", result);
      if (result.code > 399) {
      } else if (result.status === "succeeded") {
        alert("Charge succeeded!")
      }
    } catch (e) {
      console.log('Error during initialization: ', e);
    }
    // setLoading(false, 'save-card');
  }

  const deleteCard = useCallback(async (e, cardId) => {
    e.stopPropagation();
    await pgSwitchSDK.deleteCard({
      card_id: cardId,
      email: customerData.email,
    });
    await fetchCards();
    alert('Card deleted');
  }, [customerData, fieldData, billData]);

  async function updatePayment() {
    // setLoading(true);
    await pgSwitchSDK.updatePayment({
      payment_id: paymentId,
      order_id: fieldData.order_id,
      description: 'Order: ' + fieldData.order_id,
      metadata: {
        bookingId: fieldData.order_id,

      }
    })
  }

  async function creditCard() {
    let paymentElement = document.getElementById('credit-card-element');
    let buttonElement = document.getElementById('credit-card-submit');
    await pgSwitchSDK.initCreditCard(paymentElement, buttonElement);
  }


  const initializePgSwitch = useCallback(async () => {
    try {
      const checksum = generateChecksum(
        `@${fieldData.currency}|${fieldData.country_code}|${parseFloat(fieldData.amount)}|${publicKey}@`,
      );
      // make sure this id is configured correctly
      let paymentElement = document.getElementById('payment-element');
      let buttonElement = document.getElementById('submit');
      const result = await pgSwitchSDK.init(paymentElement, buttonElement, {
        checksum,
        email: customerData.email,
        country_code: fieldData.country_code,
        return_url: fieldData.return_url,
        amount_to_capture: parseFloat(fieldData.amount_to_capture),
        description: fieldData.description,
        authentication_type: fieldData.authentication_type,
        primary_residence: fieldData.primary_residence,
        ip: fieldData.ip,
        currency: fieldData.currency,
        amount: parseFloat(fieldData.amount),
        flow_type: fieldData.flow_type,
        // subscription: false,
        metadata: {
          "org_name": "codestack_ae",
          "time": "20:20",
        },
        billing: {
          address: {
            line1: billData.line1,
            line2: billData.line2,
            line3: billData.line3,
            city: billData.city,
            state: billData.state,
            zip: billData.zip,
            country: billData.country,
            first_name: customerData.first_name,
            last_name: customerData.last_name,
          },
        },
      });
      setPaymentId(result['payment_id']);
      if (buttonElement) {
        buttonElement.removeEventListener(
          'click', handlePayClick, false
        );
        buttonElement.addEventListener(
          'click', handlePayClick, false
        );
      }
      return result;
    } catch (e) {
      console.log('Error during initialization: ', e);
    }
  }, [customerData, fieldData, billData]);

  const handlePayClick = async (e) => {
    console.log("Appjs Handlepay click");
    let result = await pgSwitchSDK.handlePayClick(e);
    console.log(result);
  }


  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-6">
            <div className="mt-4">
              <form id="productForm">
                <div className="card">
                  <div className="card-header">Customer Details</div>
                  <div className="card-body">
                    <div className="row" id="customer">
                      {
                        customer ?
                          customer.map((field, i) => {
                            return <React.Fragment key={i}>
                              <div className="col-4">
                                <div className="mb-3">
                                  <label className="form-label">{field.label}</label>
                                  {
                                    field.type === 'text' ?
                                      <input className="form-control form-control-sm" type={field.type} id={field.name}
                                        name={field.name} value={customerData[field.name]} onChange={(e) => {
                                          setCustomerDetail(field.name, e.target.value);
                                        }} /> : null
                                  }
                                  {
                                    field.type === 'select' ?
                                      <select className="form-select" id={field.name} name={field.name} onChange={(e) => {
                                        console.log(field.name,e.target.value);
                                        setCustomerDetail(field.name, e.target.value);
                                      }}>
                                        {
                                          field.options.map((v) => {
                                            return <option value={v.value}>{v.label}</option>
                                          })
                                        }
                                      </select>
                                      : null
                                  }

                                </div>
                              </div>
                            </React.Fragment>
                          }) : null
                      }
                    </div>
                  </div>
                </div>
                <div className="card my-3">
                  <div className="card-header">Payment Details</div>
                  <div className="card-body">
                    <div className="row" id="payment-details">
                      {
                        fields ?
                          fields.map((field, i) => {
                            return <React.Fragment key={i}>
                              <div className="col-4">
                                <div className="mb-3">
                                  <label className="form-label">{field.label}</label>
                                  {
                                    field.type === 'text' ?
                                      <input className="form-control form-control-sm" type={field.type} id={field.name}
                                        name={field.name} value={fieldData[field.name]} onChange={(e) => {
                                          setFieldDetail(field.name, e.target.value);
                                        }} />
                                      : null
                                  }

                                  {
                                    field.type === 'select' ?
                                      <select class="form-select" id={field.name} name={field.name} onChange={(e) => {
                                        console.log(field.name,e.target.value);
                                        setFieldDetail(field.name, e.target.value);
                                      }}>
                                        {
                                          field.options.map((v) => {
                                            return <option value={v.value}>{v.label}</option>
                                          })
                                        }
                                      </select>
                                      : null
                                  }
                                </div>
                              </div>
                            </React.Fragment>
                          }) : null
                      }
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header">Billing Address</div>
                  <div className="card-body">
                    <div className="row" id="billing">
                      {
                        billing ?
                          billing.map((field, i) => {
                            return <React.Fragment key={i}>
                              <div className="col-4">
                                <div className="mb-3">
                                  <label className="form-label">{field.label}</label>
                                  <input className="form-control form-control-sm" type={field.type} id={field.name}
                                    name={field.name} value={billData[field.name]} onChange={(e) => {
                                      setBillingDetail(field.name, e.target.value);
                                    }} />
                                </div>
                              </div>
                            </React.Fragment>
                          }) : null
                      }
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="my-5">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="card-list-tab" data-bs-toggle="tab" data-bs-target="#card-list"
                    type="button" role="tab" aria-controls="home" aria-selected="true" onClick={fetchCards}>
                    Card List
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="save-card-tab" data-bs-toggle="tab" data-bs-target="#save-card" type="button"
                    role="tab" aria-controls="profile" aria-selected="false" onClick={initSaveCard}>
                    Add New Card
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="payment-tab" data-bs-toggle="tab" data-bs-target="#payment" type="button"
                    role="tab" aria-controls="payment" aria-selected="false" onClick={initPayment}>
                    Payment
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="payment-tab" data-bs-toggle="tab" data-bs-target="#payment" type="button"
                    role="tab" aria-controls="payment" aria-selected="false" onClick={confirmPayment}>
                    Confirm Payment
                  </button>
                </li>
                <li>
                  <button className="nav-link" id="payment-tab" data-bs-toggle="tab" data-bs-target="#payment" type="button"
                    role="tab" aria-controls="payment" aria-selected="false" onClick={updatePayment}>
                    Update Order Id
                  </button>
                </li>
                <li>
                  <button className="nav-link" id="credit-card-tab" data-bs-toggle="tab" data-bs-target="#credit-card" type="button"
                    role="tab" aria-controls="payment" aria-selected="false" onClick={creditCard}>
                    Credit Card
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="card-list" role="tabpanel" aria-labelledby="card-list-tab">
                  <div id="cardList" style={{
                    margin: 'auto',
                    maxWidth: '95vw',
                    fontFamily: `'Helvetica Neue', Helvetica, sans-serif`,
                    boxShadow: `
                      0 3px 6px rgba(0, 0, 0, 0.16),
                      0 3px 6px rgba(0, 0, 0, 0.23)`,
                    padding: '10px',
                    marginTop: '50px',
                    marginBottom: '50px',
                    borderRadius: '15px',
                    background: '#f9fcff',
                  }}>
                    <h1 style={{ fontWeight: '500', textAlign: 'center' }}>
                      Saved Cards
                    </h1>
                    {
                      cards && cards.length > 0 ?
                        cards.map((card, i) => {
                          return <React.Fragment key={i}>
                            <div className={`credit-card ${card.brand.toLowerCase()} selectable`}>
                              <span className="delete-card" onClick={(e) => deleteCard(e, card.id)}>X</span>
                              <div onClick={(e) => charge(card.id, card.token, card.bin)}>
                                <div className="credit-card-last4 pb-5">
                                  {card.last4}
                                </div>
                                <div className="credit-card-expiry">
                                  {card.exp_month}/{card.exp_year}
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        }) : null
                    }
                  </div>
                </div>
                <div className="tab-pane fade" id="save-card" role="tabpanel" aria-labelledby="save-card-tab">
                  <form id="save-card-form">
                    <div className="w-100 p-3 border my-4 rounded-4 box-shadow">
                      <div className="mx-3 mt-4 b-container px-3 py-3">
                        <h6 className="ms-5 mb-3">Add New Card Details</h6>
                      </div>
                      <div className="w-100 p-4">
                        <div id="save-card-element"></div>
                        <button className="btn btn-primary ms-5 mb-2 mt-2 save-card-submit" type="button">
                          <div className="spinner hidden" id="save-card-spinner"></div>
                          <span id="save-card-button-text">Save</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="tab-pane fade" id="payment" role="tabpanel" aria-labelledby="payment-tab">
                  <form id="payment-form">
                    <div className="w-100 p-3 border my-4 rounded-4 box-shadow">
                      <div className="mx-3 mt-4 b-container px-3 py-3">
                        <h6 className="ms-5 mb-3">Payment Method Details</h6>
                      </div>
                      <div className="w-100 p-4">
                        <div id="payment-element"></div>
                        <button className="btn btn-primary ms-5 mb-2 mt-2" id="submit" type="button">
                          <div className="spinner hidden" id="payment-spinner"></div>
                          <span id="payment-button-text">Pay now</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>

                <div className="tab-pane fade" id="credit-card" role="tabpanel" aria-labelledby="credit-card-tab">
                  {/* <form id="payment-form"> */}
                  <div className="w-100 p-3 border my-4 rounded-4 box-shadow">
                    <div className="mx-3 mt-4 b-container px-3 py-3">
                      <h6 className="ms-5 mb-3">Credit Card Details</h6>
                    </div>
                    <div className="w-100 p-4">
                      <div id="credit-card-element"></div>
                      <button className="btn btn-primary ms-5 mb-2 mt-2" id="credit-card-submit" type="button" onClick={passCreditCard}>
                        <div className="spinner hidden" id="payment-spinner"></div>
                        <span id="payment-button-text">Share Card</span>
                      </button>
                    </div>
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="3dSecureModal" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirm 3D Secure</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <embed id="3d_secure_url" width="200" height="200" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DefaultPage;
